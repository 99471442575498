import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CompareModal from "../../pages/actCompare/CompareModal";
import { links } from "../../redux/actions/linksAction";
import { userLogout } from "../../redux/actions/loginAction";
import { Badge } from "antd";
import "./sidebar.css";
import WarningModal from "../../pages/warning/WarningModal";

const Sidebar = ({ menu, setMenu, setActData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isPhone, setIsPhone] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isMineralsOpen, setIsMineralsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { link } = useSelector((state) => state.link);
  const changeLink = (name) => {
    dispatch(links(name));
  };

  const logout = () => {
    dispatch(userLogout());
  };

  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsPhone(true);
    } else {
      setIsPhone(false);
    }

    if (link == "/lab/import") {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    if (link == "/registration/certificate/minerals") {
      setIsMineralsOpen(true);
    } else {
      setIsMineralsOpen(false);
    }
  }, [window.innerWidth, link]);

  return (
    <div
      className={
        menu
          ? "sidebar-component sidebar-component-active"
          : "sidebar-component"
      }
    >
      <WarningModal
      // open={true} setOpen={setOpen}
      />
      <CompareModal open={isOpenModal} setOpen={setIsOpenModal} />
      <div className="top">
        <div className="divider">
          <div className="d-line"></div>
          <div className="d-text">{t("navbar.services")}</div>
        </div>
        <Link to="/kr" onClick={() => (isPhone ? setMenu(!menu) : null)}>
          <div
            className={link === "/kr" ? "link active-link" : "link"}
            onClick={() => changeLink("/kr")}
          >
            <div className="line"></div>
            <div className="bg"></div>
            <h1>{t("navbar.kr")}</h1>
          </div>
        </Link>
        <Link to="/akd" onClick={() => (isPhone ? setMenu(!menu) : null)}>
          <div
            className={link === "/akd" ? "link active-link" : "link"}
            onClick={() => changeLink("/akd")}
          >
            <div className="line"></div>
            <div className="bg"></div>
            <h1>{t("navbar.akd")}</h1>
          </div>
        </Link>
        <Link
          to="/export-fsc"
          onClick={() => (isPhone ? setMenu(!menu) : null)}
        >
          <div
            className={link === "/export-fsc" ? "link active-link" : "link"}
            onClick={() => changeLink("/export-fsc")}
          >
            <div className="line"></div>
            <div className="bg"></div>
            <h1>{t("navbar.export-fcc")}</h1>
          </div>
        </Link>
        <Link to="/local-fsc" onClick={() => (isPhone ? setMenu(!menu) : null)}>
          <div
            className={link === "/local-fsc" ? "link active-link" : "link"}
            onClick={() => changeLink("/local-fsc")}
          >
            <div className="line"></div>
            <div className="bg"></div>
            <h1>{t("navbar.inner-fcc")}</h1>
          </div>
        </Link>
        <a
          to="#"
          // onClick={() => setIsExpanded(!isExpanded)}
        ></a>
        <Link
          to="/fumigation"
          onClick={() => (isPhone ? setMenu(!menu) : null)}
        >
          <div
            className={link === "/fumigation" ? "link active-link" : "link"}
            onClick={() => changeLink("/fumigation")}
          >
            <div className="line"></div>
            <div className="bg"></div>
            <h1>{t("navbar.fum")}</h1>
          </div>
        </Link>

        <a to="#">
          <div
            className={
              link === "/lab/import" && isOpen ? "link opened" : "link"
            }
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="line"></div>
            <div className="bg"></div>
            <h1>{t("navbar.lab")}</h1>
            <div className={isOpen ? "down active" : "down"}></div>
          </div>
          <div className={isOpen ? "submenu  expanded" : "submenu"}>
            <Link
              to="/lab/import"
              onClick={() => (isPhone ? setMenu(!menu) : null)}
            >
              <div
                className={link === "/lab/import" ? "link active-link" : "link"}
                onClick={() => changeLink("/lab/import")}
              >
                <h1>{t("navbar.import")}</h1>
              </div>
            </Link>
          </div>
          <div className={isOpen ? "submenu  expanded" : "submenu"}>
            <Link
              to="/lab/export"
              onClick={() => (isPhone ? setMenu(!menu) : null)}
            >
              <div
                className={link === "/lab/export" ? "link active-link" : "link"}
                onClick={() => changeLink("/lab/export")}
              >
                <h1>{t("navbar.export")}</h1>
              </div>
            </Link>
          </div>
          <div className={isOpen ? "submenu  expanded" : "submenu"}>
            <Link
              to="/lab/field"
              onClick={() => (isPhone ? setMenu(!menu) : null)}
            >
              <div
                className={link === "/lab/field" ? "link active-link" : "link"}
                onClick={() => changeLink("/lab/field")}
              >
                <h1>{t("lab.field")}</h1>
              </div>
            </Link>
          </div>
        </a>
        {/* minerals */}
        <Link
          to="/registration/certificates"
          onClick={() => (isPhone ? setMenu(!menu) : null)}
        >
          <div
            className={
              link === "/registration/certificates"
                ? "link active-link"
                : "link"
            }
            onClick={() => changeLink("/registration/certificates")}
          >
            <div className="line"></div>
            <div className="bg"></div>
            <h1>{t("navbar.cert")}</h1>
          </div>
        </Link>

        {/* SeedExport */}
        <Link
          to="/seed-export"
          onClick={() => (isPhone ? setMenu(!menu) : null)}
        >
          <div
            className={link === "/seed-export" ? "link active-link" : "link"}
            onClick={() => changeLink("/seed-export")}
          >
            <div className="line"></div>
            <div className="bg"></div>
            <h1>{t("seed-export short")}</h1>
          </div>
        </Link>

        <div className="divider">
          <div className="d-line"></div>
          <div className="d-text">{t("navbar.account")}</div>
        </div>
        <Link to="/invoice" onClick={() => (isPhone ? setMenu(!menu) : null)}>
          <div
            className={link === "/invoice" ? "link active-link" : "link"}
            onClick={() => changeLink("/invoice")}
          >
            <div className="line"></div>
            <div className="bg"></div>
            <h1>{t("navbar.oferta")}</h1>
          </div>
        </Link>

        <Link to="/balance" onClick={() => (isPhone ? setMenu(!menu) : null)}>
          <div
            className={link === "/balance" ? "link active-link" : "link"}
            onClick={() => changeLink("/balance")}
          >
            <div className="line"></div>
            <div className="bg"></div>
            <h1>{t("navbar.balans")}</h1>
          </div>
        </Link>

        <a>
          <div
            style={{ paddingRight: "60px" }}
            className="link"
            onClick={() => setIsOpenModal(true)}
          >
            <div className="line"></div>
            <div className="bg"></div>
            <h1 style={{ width: "132px" }}>{t("navbar.compare")}</h1>
            {/* <div className="badge-new">
              <Badge count={"new"} />
            </div> */}
          </div>
        </a>

        <div className="divider">
          <div className="d-line"></div>
          <div className="d-text">{t("navbar.helpp")}</div>
        </div>
        <Link
          to="/instruction"
          onClick={() => (isPhone ? setMenu(!menu) : null)}
        >
          <div
            className={link === "/instruction" ? "link active-link" : "link"}
            onClick={() => changeLink("/instruction")}
          >
            <div className="line"></div>
            <div className="bg"></div>
            <h1>{t("navbar.ins")}</h1>
          </div>
        </Link>
        {/* <Link to="/faq" onClick={() => (isPhone ? setMenu(!menu) : null)}>
          <div
            className={link === "/faq" ? "link active-link" : "link"}
            onClick={() => changeLink("/faq")}
          >
            <div className="line"></div>
            <div className="bg"></div>
            <h1>{t("navbar.faq")}</h1>
          </div>
        </Link> */}
        <a
          href="https://t.me/+hXOxg-BJ5ys0YWIy"
          target="_blank"
          rel="noreferrer"
          onClick={() => (isPhone ? setMenu(!menu) : null)}
        >
          <div className={link === "/help" ? "link active-link" : "link"}>
            <div className="line"></div>
            <div className="bg"></div>
            <h1>{t("navbar.help")}</h1>
          </div>
        </a>

        <div className="divider">
          <div className="d-line"></div>
          <div className="d-text">{t("navbar.other")}</div>
        </div>

        <Link to="/agrohelp" onClick={() => (isPhone ? setMenu(!menu) : null)}>
          <div
            className={
              link === "/agrohelp" ||
              link === "/agrohelp/1" ||
              link === "/agrohelp/2" ||
              link === "/agrohelp/3" ||
              link === "/agrohelp/4" ||
              link === "/agrohelp/5" ||
              link === "/agrohelp/6" ||
              link === "/agrohelp/7"
                ? "link active-link"
                : "link"
            }
            onClick={() => changeLink("/agrohelp")}
          >
            <div className="line"></div>
            <div className="bg"></div>
            <h1>{t("navbar.agro")}</h1>
          </div>
        </Link>
      </div>

      <div className="bottom">
        <a href="https://karantin.uz">
          <div
            className={"link"}
            onClick={() => {
              // changeLink("/invoice");
              logout();
            }}
          >
            <div className="line"></div>
            <div className="bg"></div>
            <h1>{t("navbar.logout")}</h1>
          </div>
        </a>
        <p>© {new Date().getFullYear()} | All rights reserved.</p>
      </div>
    </div>
  );
};

export default Sidebar;
