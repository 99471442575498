import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLogin, userLogout } from "../../redux/actions/loginAction";
import { navigate } from "../../utils/navigate";
import queryString from "query-string";
import Loader from "../loader/Loader";
import { API_URL } from "../../constants/variables";
import { requestRegAuth } from "../../helpers/createRequest";
import { userPinLogin } from "../../redux/actions/regLoginAction";

const Login = (props) => {
  const dispatch = useDispatch();
  const baseURL = API_URL;
  const newToken = JSON.parse(localStorage.getItem("user_data"))?.token;

  const { token, pinOrTin, email, name, phone } = useSelector(
    (state) => state.login
  );

  const queryParams = queryString.parse(window.location.search);

  let code = queryParams.code;
  let esi = queryParams.state === "esi";
  let stateTxt = queryParams.state;

  const pinLoginHandle = (data, dataObj) => {
    requestRegAuth
      .post("user/pin/login", {
        pinfl: data.directorPinfl,
        full_name: data.director,
        email: dataObj.email,
        phone: dataObj.mob_phone_no,
        company_name: data.name,
      })
      .then((res) => {
        if (res.data.success) {
          dispatch(userPinLogin(res.data.data));
          if (esi) {
            window.location.href = "https://cabinet.karantin.uz/invoice";
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const login = () => {
    axios
      .post(`${baseURL}/applicant/auth/one-id/`, {
        code: code,
        esi: esi ? true : undefined,
      })
      .then((res) => {
        dispatch(userLogin(res.data));
        if (stateTxt == "fumPrice") {
          window.location.href = "fumigation?price=1";
        }
        if (res.data.pin_or_tin?.length === 9) {
          axios
            .get(`https://api.didox.uz/v1/profile/${res.data.pin_or_tin}`)
            .then((resp) => {
              console.log(resp);
              if (resp.status === 200) {
                localStorage.setItem("applicant", JSON.stringify(resp.data));
                pinLoginHandle(resp.data, res.data);
              }
            });
        }

        // window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          navigate();
          dispatch(userLogout());
        }
        if (err?.response?.status === 403) {
          alert("Xatolik! Birozdan so'ng urinib ko'ring");
          window.location.replace("https://karantin.uz");
          dispatch(userLogout);
        }
      });
  };

  useEffect(() => {
    if (!code) {
      navigate();
    } else {
      localStorage.removeItem("pop_status");
      login();
    }
  }, []);

  return (
    <div>
      <Loader />
    </div>
  );
};

export default Login;
