export const curh = window.location.hostname;
export let redirectUrl;
let stateTxt;
// export let redirectUrl = window.location.href;

if (curh == "localhost") {
  // dev code
  redirectUrl = "http://localhost:3000/";
} else {
  // production code
  redirectUrl = "https://cabinet.karantin.uz/";
}

if (window.location.search.substring(1) == "price=1") {
  stateTxt = "fumPrice";
} else {
  stateTxt = "test";
}

export function navigate() {
  window.location.href = `https://sso.egov.uz/sso/oauth/Authorization.do?response_type=one_code&client_id=efito_uz&redirect_uri=${redirectUrl}&scope=efito_uz&state=${stateTxt}`;
}
export function navigateEri() {
  const eriRedirect = "https://cabinet.karantin.uz/esi-auth";
  window.location.href = `https://esi.uz/oauth2/authorize?client_id=B1358F66C1F06D98&scope=certificate-info&response_type=code&state=esi&redirect_uri=${eriRedirect}`;
}

export default redirectUrl;
